'use strict';

var util = require('@foundation/js/util');
var dialog = require('@foundation/js/dialog');

function initializeEvents() {

    // discount banner
    $('.promo-msg-link').on('click', function (e) {

        e.preventDefault();

        var viewportWidth = window.innerWidth - 20;
        var viewportHeight = window.innerHeight - 20;

        if (viewportWidth > 1000) {
            viewportWidth = 340;
        }
        if (viewportHeight > 500) {
            viewportHeight = 'auto';
        }

        var $markup = $('.promo-msg-cntr').find('.promo-msg-dialog-content').html();

        // eslint-disable-next-line no-shadow
        function initModalBtn(dialog) {
            // reset on resize
            dialog.find('.content-asset').css('top', '0px');

            if (dialog.outerHeight() > dialog.parent().height()) {
                var scrollbutton = $('<button class="modalscroll down"/>');
                dialog.parent().append(scrollbutton);

                var button = dialog.parent().find('.modalscroll');
                var modalheight = dialog.parent().height();
                var contentheight = dialog.outerHeight();
                var difference = contentheight - modalheight + 40;

                button.on('click', function () {
                    if (button.hasClass('down')) {
                        dialog.find('.content-asset').animate({
                            top: '-' + difference + 'px'
                        },
                            {
                                duration: 1000,
                                complete: function () {
                                    button.removeClass('down').addClass('up');
                                }
                            });
                    } else {
                        dialog.find('.content-asset').animate({
                            top: '0px'
                        },
                            {
                                duration: 1000,
                                complete: function () {
                                    button.removeClass('up').addClass('down');
                                }
                            });
                    }
                });
            }
        }
        var halfWindowHeight = window.innerHeight / 2;

        dialog.open({
            html: $markup,
            options: {
                resizable: false,
                draggable: false,
                height: viewportHeight,
                width: viewportWidth,
                dialogClass: 'promo-dialog',
                modal: true,
                position: {
                    my: 'center center',
                    at: 'center top+' + halfWindowHeight,
                    of: window,
                    collision: 'flipfit'
                },
                beforeClose: function () {
                    $('body').css('overflow', 'auto');
                    $('body').addClass('allow-scroll-on-body');
                },
                open: function () {
                    $('body').css('overflow', 'hidden');
                    $('body').removeClass('allow-scroll-on-body');

                    // fix weird positioning bug on mobile
                    var $dialog = $(this);
                    var left = Math.max(($(window).width() / 2) - ($dialog[0].offsetWidth / 2), 0);

                    $dialog.parent().css({
                        top: '0',
                        left: left + 'px',
                        position: 'fixed'
                    });

                    $('.ui-widget-overlay').on('click', function () {
                        $('.ui-dialog-content').dialog('close');
                    });

                    initModalBtn($dialog);

                    $(window).on('resize', _.debounce(
                        function () {
                            $('body').css('overflow', 'hidden');
                            $('.modalscroll').remove();
                            initModalBtn($dialog);
                        },
                        200)
                    );
                    $('.ui-dialog-title').html($('.promo-msg-dialog-title').html());
                    util.hideEmptyElements('.ui-dialog-title');
                }
            }
        });
    });
}


exports.init = function() {
    initializeEvents();
}
