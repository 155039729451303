'use strict';

function overrideGlobalMsg() {
    //override” the global message targeting by category ID (DIGITAL-16437)
    var headerHomeBanner = document.querySelectorAll('.header-banner .header-promotion:not(#cat-topbanner .header-promotion)');
    var headerHomeBannerContent = headerHomeBanner.length > 0 ? headerHomeBanner[0].innerHTML.trim().length : 0;
    var headerCatTopBanner = document.querySelector('#cat-topbanner');
    var headerCatBanner = headerCatTopBanner ? headerCatTopBanner.querySelectorAll('div.header-promotion') : [];
    var headerCatBannerContent = headerCatBanner.length > 0 ? headerCatBanner[0].innerHTML.trim().length : 0;
    var headerHomeAvailable = headerHomeBanner.length > 0 && headerHomeBannerContent;

    //Only available Home/global banner
    if (headerCatBannerContent == 0 || headerHomeAvailable) {
        if (headerCatTopBanner) {
            headerCatTopBanner.remove();
        }
        if (headerHomeBanner.length) {
            headerHomeBanner[0].style.display = 'block';
        }
    }
    //Category banner available - overriding the global promo
    if (headerCatBannerContent) {
        if (headerCatTopBanner) {
            headerCatTopBanner.style.display = 'block';
        }
        if (headerCatBanner.length) {
            headerCatBanner[0].style.display = 'block';
        }
        if (headerHomeBanner.length) {
            headerHomeBanner[0].remove();
        }
    }
}

exports.init = function () {
    overrideGlobalMsg();
}