'use strict';
var util = require('@foundation/js/util');

function initializeEvents() {
    const $navigation = $('#navigation');
    const MENU_ACTIVE_CLASS = 'menu-active';
    const FADE_IN_CLASS = 'fadein';
    const SUB_NAV_ACTIVE_CLASS = 'sub-nav-active';
    var windowHeight = $(window).height();
    var $body = $('body');
    var $header = $('header');
    var $topBanner = $('#top-banner');
    var $navigationContainer = $navigation.find('.js-navigation-container');
    var $wrapper = $('#wrapper');

    $navigation.find('.js-nav-level-2-item').children('.js-open-l2-cat').off('click touch').on('click touch', function (e) {
        e.preventDefault();
        const $button = $(this);
        const $item = $button.closest('.js-nav-level-2-item'); // li.js-nav-level-2-item
        var url = $item.data('url');
        window.location.href = url;

    });

    // hamburger toggle
    $topBanner.find('.js-menu-toggle-btn').off('click touch').on('click touch', function (e) {
        e.preventDefault();

        // new start
        $wrapper.toggleClass(MENU_ACTIVE_CLASS);
        util.setTopPosition();

        if ($wrapper.hasClass(MENU_ACTIVE_CLASS)) {
            window.MedalliaSurveytShow = false;
            util.feedbackBtnHide();
            $('body').addClass('hide-vendor-icons');
            $('.menu-mask').addClass('menu-mask-active');
            $wrapper.css({height: windowHeight});
            $('.menu-mask').off('click touch').on('click touch', function () {
                $('.js-menu-toggle-btn').trigger('click');
            });
            $('.js-menu-toggle-btn').attr('aria-expanded', true);
            $navigation.attr('aria-expanded', true).attr('aria-hidden', false);
            $navigationContainer.attr('hidden', false);
            $body.addClass('stop-scroll-on-body');

            // loop through and fade in nav list items
            setTimeout(function fadeNav() {
                $('.js-nav-level-1-item > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
                $('.mobile-utility > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
                $('.nav-l1-recomm-container.js-nav-fade-in-element').addClass(FADE_IN_CLASS);
            }, 350); // delay for nav to finish slide in

            $navigation.css({overflow: 'auto'});
            var $mobileRecoAsset =  $navigation.find('.nav-l1-recomm-container');
            document.dispatchEvent(new CustomEvent('contentChanged', {detail: {action: 'flyout_in', cont: $mobileRecoAsset.length && $mobileRecoAsset[0] }}));
        } else {
            window.MedalliaSurveytShow = true;
            util.feedbackBtnShow();
            $('body').removeClass('hide-vendor-icons');
            $('.menu-mask').removeClass('menu-mask-active');
            $wrapper.css('height', '');
            $navigation.attr('aria-expanded', false).attr('aria-hidden', true);
            $navigationContainer.attr('hidden', true);
            $('.js-menu-toggle-btn').attr('aria-expanded', false);

            if ($('#flyoutsearch').attr('aria-expanded') == 'false') {
                $body.removeClass('stop-scroll-on-body');
            }

            $('.js-nav-fade-in-element').removeClass(FADE_IN_CLASS);

            $topBanner.removeClass(SUB_NAV_ACTIVE_CLASS);
            $navigation.css({overflow: 'visible'});
        }

        $navigation.scrollTop(0);

        // close nav panels
        $navigation.find('.js-nav-level-2').hide(); // L2 nav wrapper
        $navigation.find('.js-nav-level-2-wrapper .js-nav-level-3').hide(); // L3 nav wrapper
        $navigation.find('.js-nav-level-1-item, .js-nav-level-2-item').removeClass('active') // li.js-nav-level-1-item, li.js-nav-level-2-item
            .children('.js-mobile-open-sub-menu').attr('aria-expanded', false);
        $('.nav-country-selector-cntr').css('display','none');
        $(window).trigger('scroll');
    });

    $topBanner.off('click touch', '.js-nav-mobile-back-btn').on('click touch', '.js-nav-mobile-back-btn', function (e) {
        e.preventDefault();

        const $button = $(this);

        util.setTopPosition();

        if ($button.data('scrollUnlock')) {
            $navigation.css({overflow: 'auto'});
        }

        let hideBackBtn = false;
        let isLevel2Active = true;
        let isLevel1Active = false;
        let $item = $('.js-nav-level-2-item.active').first();

        if (!$item.length) {
            isLevel1Active = true;
            isLevel2Active = false;
            hideBackBtn = true;
            $item = $('.js-nav-level-1-item.active').first();
        }
        if ($('.navigation').hasClass('backfromcountryselector')) {
            $('.mobile-utility > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
            $('.js-nav-level-1-item > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
            $('.nav-country-selector-cntr').css('display', 'none');
            $('.navigation').removeClass('backfromcountryselector');
        }

        if ($item.length) {
            $item.children('.js-mobile-open-sub-menu').attr('aria-expanded', false);

            // close nav panel
            $item.children('.js-nav-level-wrapper').hide('slide', {
                direction: 'right'
            }, 500);
            setTimeout(function() {
                $item.children('.js-nav-level-wrapper').find('.js-nav-scrollable-wrapper').scrollTop(0);
            }, 500);

            if(isLevel1Active) {
                $item.children('.js-nav-level-wrapper').find('.js-nav-level-2-item > .js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
                $item.children('.js-nav-level-wrapper').find('.js-flyout-slot-content.js-nav-fade-in-element').first().removeClass(FADE_IN_CLASS);
                $item.closest('.js-nav-level-wrapper').find('.js-nav-level-1-item > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
                $('.mobile-utility > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
            } else if(isLevel2Active) {
                $item.children('.js-nav-level-wrapper').find('.js-nav-level-3-item > .js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
                $item.children('.js-nav-level-wrapper').find('.js-flyout-slot-content.js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
                $item.closest('.js-nav-level-wrapper').find('.js-nav-level-2-item > .js-nav-fade-in-element').addClass(FADE_IN_CLASS);
            } else {
                $item.children('.js-nav-level-wrapper').find('.js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
            }

            $item.removeClass('active');
        }

        if (hideBackBtn) {
            $topBanner.removeClass(SUB_NAV_ACTIVE_CLASS);
        } else {
            // copy proper title value
            const $flyoutTitleWrapper = $topBanner.find('.js-flyout-title-wrapper');

            $flyoutTitleWrapper.fadeOut(250, function () {
                $flyoutTitleWrapper.html($item.closest('.js-nav-level-wrapper').find('> .js-menu-wrap > .js-nav-flyout-top .js-nav-flyout-title-content').html() || '');
                $flyoutTitleWrapper.fadeIn(250);
            });
            $button.data('scrollUnlock', true);
        }
    });
}

exports.init = function() {
    initializeEvents();

}