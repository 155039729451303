'use strict';

function initializeMobileEvents() {
    $('.js-country-selector-wrapper').on('click',function(e) {
        e.preventDefault();
        const $topBanner = $('#top-banner');
        const SUB_NAV_ACTIVE_CLASS = 'sub-nav-active';
        const $navigation = $('#navigation');
        const FADE_IN_CLASS = 'fadein';
        // hide logo and show flyout title
        $topBanner.addClass(SUB_NAV_ACTIVE_CLASS);
        $topBanner.find('.js-flyout-title-wrapper').html($('.current-country').html() || '');
        $navigation.scrollTop(0).css({ overflow: 'hidden' });
        $('.mobile-utility > .js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
        $('.js-nav-level-1-item > .js-nav-fade-in-element').removeClass(FADE_IN_CLASS);
        $('.nav-country-selector-cntr').css('display','flex');
        // append countries
        if (!$('.nav-country-selector-cntr').children().length) {
            $('.nav-country-selector-cntr').append($('.mobile-utility .selector').html());
        }
        $('.navigation').addClass('backfromcountryselector');
    });
}


exports.init = function() {
    var viewtype = require('@foundation/js/viewtype');
    if (viewtype.isMediumViewAndDown()) {
        initializeMobileEvents();
    }

}